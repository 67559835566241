import React, { Component } from 'react'
import { Redirect } from '@reach/router'

export default class SiteIndex extends Component {

    render (){
        return (
            <div>
                <Redirect to="en" noThrow />
            </div>
        )
    }
}